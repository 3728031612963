<template>
  <div class="contact">
    <div class="flash-message" v-if="flashMessage == true">
      Votre email a bien été envoyé. PAREENA PI vous répondra dans les plus
      brefs délais.
      <p class="flash-message-x" @click="flashMessage = false">x</p>
    </div>
    <h1>contactez-nous</h1>
    <p class="description">
      L'équipe de "PAREENA PI" aura le plaisir de répondre à toutes vos
      interrogations et à la fabrication de toutes vos confections
    </p>
    <div class="icons">
      <a
        href="https://www.instagram.com/pareena.pi/"
        title="Instagram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-instagram"></i>
      </a>
      <a
        href="https://www.facebook.com/pareena.nogues"
        title="Facebook"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-facebook"></i>
      </a>
    </div>
    <form
      action="https://formsubmit.co/pareena.pi@gmail.com"
      method="POST"
      @click="submit"
    >
      <div class="name">
        <div class="name-item">
          <label for="firstname">Prénom</label>
          <input
            type="text"
            name="firstname"
            id="firstname"
            placeholder="Prénom"
            required
          />
        </div>
        <div class="name-item">
          <label for="lastname">Nom</label>
          <input
            type="text"
            name="lastname"
            id="lastname"
            placeholder="Nom"
            required
          />
        </div>
      </div>

      <label for="lastname">Email</label>
      <input
        type="email"
        name="email"
        id="email"
        placeholder="Email"
        required
      />

      <label for="message">Message</label>
      <textarea
        id="message"
        name="message"
        placeholder="Votre message..."
        style="height: 200px"
        required
      ></textarea>

      <input type="submit" value="Envoyer" />
    </form>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: function () {
    return {
      flashMessage: false,
    };
  },
  components: {},
  methods: {
    submit() {
      let fn = document.getElementById("firstname").value;
      let ln = document.getElementById("lastname").value;
      let email = document.getElementById("email").value;
      let message = document.getElementById("message").value;
      if (fn !== "" && ln !== "" && email !== "" && message !== "") {
        this.flashMessage = true;
      }
    },
  },
};
</script>
